import React from "react";

const FacebookLogo = () => {
  return (
    <svg height={"50px"} width={"50px"} viewBox="0 0 406.065 406.065"className="svg-icon">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="-1383.689"
        y1="1177.103"
        x2="-1382.689"
        y2="1177.103"
        gradientTransform="rotate(52.827 136349.564 548347.405) scale(276.6438)"
      >
        <stop offset="0" stopColor="#455e9b" />
        <stop offset="1" stopColor="#5a70b3" />
      </linearGradient>
      <path
        d="M22.969 144.483v177.765c0 33.643 27.208 60.852 60.847 60.852H322.25c33.641 0 60.848-27.209 60.848-60.852V145.105c-51.462 13.454-112.911 21.27-178.885 21.27-67.007 0-129.314-8.058-181.244-21.892M.055 70.038A74.62 74.62 0 0 0 0 72.882v260.303c0 40.172 32.708 72.881 72.888 72.881H333.18c40.179 0 72.885-32.709 72.885-72.881V72.882c0-.49-.005-.983-.015-1.471v67.114c-3.06.958-6.164 1.897-9.309 2.813v185.867c0 38.336-31.204 69.541-69.539 69.541H78.862c-38.334 0-69.54-31.205-69.54-69.541v-186.56c-3.146-.934-6.25-1.89-9.308-2.866V72.473c.001-.814.014-1.627.041-2.435"
        fill="url(#a)"
      />
      <path
        d="M22.969 83.728c0-33.553 27.208-60.845 60.847-60.845H322.25c33.641 0 60.848 27.292 60.848 60.845v238.521c0 33.643-27.207 60.852-60.848 60.852H83.815c-33.639 0-60.847-27.209-60.847-60.852l.001-238.521M9.322 78.86v248.345c0 38.336 31.206 69.541 69.54 69.541h248.341c38.335 0 69.539-31.205 69.539-69.541V78.86c0-38.332-31.204-69.538-69.539-69.538H78.862c-38.334 0-69.54 31.206-69.54 69.538"
        fill="#8091c1"
      />
      <path
        d="M336.51 0H68.783c-1.8 0-3.578.091-5.345.226C28.502 4.782 1.239 34.169.055 70.038 1.554 31.175 33.66 0 72.887 0H333.18c39.688 0 72.085 31.912 72.87 71.411v-.141c-.587-35.795-27.114-65.417-61.567-70.8-2.618-.3-5.275-.47-7.973-.47"
        fill="#d6ddf0"
      />
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        x1="-1383.689"
        y1="1176.838"
        x2="-1382.689"
        y2="1176.838"
        gradientTransform="rotate(52.827 136349.564 548347.405) scale(276.6438)"
      >
        <stop offset="0" stopColor="#697bb0" />
        <stop offset="1" stopColor="#7688c2" />
      </linearGradient>
      <path
        d="M322.249 22.882H83.815c-33.639 0-60.847 27.292-60.847 60.845v60.755c51.93 13.834 114.236 21.892 181.242 21.892 65.974 0 127.423-7.816 178.885-21.27V83.728c.001-33.553-27.206-60.846-60.846-60.846M333.18 0H72.887C33.66 0 1.554 31.175.055 70.038c-.026.808-.04 1.621-.04 2.435v65.305a465.32 465.32 0 0 0 9.308 2.866V78.86c0-38.332 31.206-69.538 69.54-69.538h248.341c38.335 0 69.539 31.206 69.539 69.538v62.478a479.45 479.45 0 0 0 9.309-2.813V71.411C405.265 31.912 372.868 0 333.18 0"
        fill="url(#b)"
      />
      <path
        d="M327.203 9.322H78.862c-38.334 0-69.54 31.206-69.54 69.538v61.784a503.287 503.287 0 0 0 13.646 3.838V83.728c0-33.553 27.208-60.845 60.847-60.845h238.434c33.641 0 60.848 27.292 60.848 60.845v61.378a509.178 509.178 0 0 0 13.646-3.767V78.86c-.001-38.332-31.205-69.538-69.54-69.538"
        fill="#8d9fcb"
      />
      <path
        d="M311.982 109.705h26.031v-52.68h-56.437c-34.275 0-62.062 27.786-62.062 62.062v31.515h-44.521v52.681h44.521v162.21h69.238v-162.21h49.259v-52.681h-49.259V132.93c.004-12.826 10.403-23.225 23.23-23.225z"
        fill="#fff"
      />
    </svg>
  );
};

export default FacebookLogo;
