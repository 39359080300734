import React from "react";

const Node = () => {
  return (
    <svg
      width="120px"
      height="120px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>file_type_node2</title>
      <path
        d="M16,2A14,14,0,1,1,2,16,14,14,0,0,1,16,2Z"
        style={{fill:"#83cd29",fillRule:'evenodd'}}
      />
      <path
        d="M16,30a2.151,2.151,0,0,1-1.076-.288L11.5,27.685c-.511-.286-.262-.387-.093-.446a6.828,6.828,0,0,0,1.549-.7.263.263,0,0,1,.255.019l2.631,1.563a.34.34,0,0,0,.318,0l10.26-5.922a.323.323,0,0,0,.157-.278V10.075a.331.331,0,0,0-.159-.283L16.158,3.875a.323.323,0,0,0-.317,0L5.587,9.794a.33.33,0,0,0-.162.281V21.916a.315.315,0,0,0,.161.274L8.4,23.814c1.525.762,2.459-.136,2.459-1.038V11.085a.3.3,0,0,1,.3-.3h1.3a.3.3,0,0,1,.3.3V22.777c0,2.035-1.108,3.2-3.038,3.2a4.389,4.389,0,0,1-2.363-.642L4.661,23.788a2.166,2.166,0,0,1-1.076-1.872V10.075A2.162,2.162,0,0,1,4.661,8.2L14.922,2.276a2.246,2.246,0,0,1,2.156,0L27.338,8.2a2.165,2.165,0,0,1,1.077,1.87V21.916a2.171,2.171,0,0,1-1.077,1.872l-10.26,5.924A2.152,2.152,0,0,1,16,30Zm3.488-8.257c3.251,0,5.115-1.28,5.115-3.516,0-2.216-1.5-2.807-4.651-3.223-3.186-.422-3.51-.639-3.51-1.385,0-.616.274-1.438,2.634-1.438,2.108,0,2.885.454,3.2,1.875a.3.3,0,0,0,.288.232H23.9a.3.3,0,0,0,.295-.323c-.206-2.448-1.832-3.589-5.12-3.589-2.925,0-4.67,1.235-4.67,3.305,0,2.246,1.736,2.866,4.544,3.144,3.359.329,3.62.82,3.62,1.481,0,1.147-.92,1.636-3.082,1.636-2.715,0-3.313-.682-3.513-2.032a.3.3,0,0,0-.295-.251H14.351a.3.3,0,0,0-.3.3C14.054,19.682,14.995,21.743,19.485,21.743Z"
        style={{fill:"#fff"}}
      />
    </svg>
  );
};

export default Node;
