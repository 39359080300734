import React from "react";

const LinkedLogo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: "new 0 0 512 512" }}
      xmlSpace="preserve"
      height={"50px"}
      width={"50px"}
      className="svg-icon"
    >
      <path
        style={{ fill: "#3CAFE6" }}
        d="M469.333,0H42.667C19.14,0,0,19.14,0,42.667v426.667C0,492.86,19.14,512,42.667,512h426.667
      C492.86,512,512,492.86,512,469.333V42.667C512,19.14,492.86,0,469.333,0z"
      />
      <path
        style={{ fill: "#1C9AD6" }}
        d="M503.467,477.867H59.733c-14.139,0-25.6-11.461-25.6-25.6V8.533c0-2.667,0.257-5.273,0.725-7.805
      C15.05,4.41,0,21.808,0,42.667v426.667C0,492.86,19.14,512,42.667,512h426.667c20.859,0,38.257-15.05,41.938-34.859
      C508.739,477.61,506.133,477.867,503.467,477.867z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M153.6,187.733H85.333c-4.713,0-8.533,3.821-8.533,8.533v230.4c0,4.713,3.821,8.533,8.533,8.533
      H153.6c4.713,0,8.533-3.821,8.533-8.533v-230.4C162.133,191.554,158.313,187.733,153.6,187.733z"
      />
      <path
        style={{ fill: "#E5E5E5" }}
        d="M93.867,426.667v-230.4c0-4.713,3.821-8.533,8.533-8.533H85.333c-4.713,0-8.533,3.821-8.533,8.533
      v230.4c0,4.713,3.821,8.533,8.533,8.533H102.4C97.687,435.2,93.867,431.379,93.867,426.667z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M408.08,209.777c-16.38-14.01-37.598-22.044-58.213-22.044c-33.25,0-54.588,10.82-68.267,24.335
      v-15.802c0-4.713-3.821-8.533-8.533-8.533H204.8c-4.713,0-8.533,3.821-8.533,8.533v230.4c0,4.713,3.821,8.533,8.533,8.533h68.267
      c4.713,0,8.533-3.821,8.533-8.533V315.733c0-21.084,17.717-34.133,34.133-34.133c19.778,0,34.133,14.355,34.133,34.133v110.933
      c0,4.713,3.821,8.533,8.533,8.533h68.267c4.713,0,8.533-3.821,8.533-8.533V264.533C435.2,244.18,425.569,224.734,408.08,209.777z"
      />
      <g>
        <path
          style={{ fill: "#E5E5E5" }}
          d="M213.333,426.667v-230.4c0-4.713,3.821-8.533,8.533-8.533H204.8c-4.713,0-8.533,3.821-8.533,8.533
        v230.4c0,4.713,3.821,8.533,8.533,8.533h17.067C217.154,435.2,213.333,431.379,213.333,426.667z"
        />
        <path
          style={{ fill: "#E5E5E5" }}
          d="M366.933,426.667V315.733c0-19.778-14.355-34.133-34.133-34.133c-2.701,0-5.437,0.361-8.124,1.044
        c15.017,3.635,25.19,16.439,25.19,33.089v110.933c0,4.713,3.821,8.533,8.533,8.533h17.067
        C370.754,435.2,366.933,431.379,366.933,426.667z"
        />
      </g>
      <path
        style={{ fill: "#FFFFFF" }}
        d="M119.467,76.8C95.94,76.8,76.8,95.94,76.8,119.467s19.14,42.667,42.667,42.667
      s42.667-19.14,42.667-42.667S142.993,76.8,119.467,76.8z"
      />
      <path
        style={{ fill: "#E5E5E5" }}
        d="M128,145.067c-23.526,0-42.667-19.14-42.667-42.667c0-3.456,0.423-6.814,1.203-10.033
      c-6.08,7.374-9.737,16.818-9.737,27.1c0,23.526,19.14,42.667,42.667,42.667c20.07,0,36.938-13.934,41.463-32.634
      C153.099,139.001,141.245,145.067,128,145.067z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default LinkedLogo;
